import "regenerator-runtime/runtime";

import jquery from 'jquery';
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import './scss/index.scss';
import '@popperjs/core';
import 'bootstrap';

import "slick-carousel";
/* var scrollMonitor = require("scrollmonitor"); */
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import axios from "axios";
//Vue e Vue Validate
import Vue from "vue";
import {
    ValidationProvider,
    ValidationObserver,
    extend,
    localize,
} from "vee-validate";
import {
    required,
    email,
    numeric,
    max,
    alpha,
    alpha_spaces,
    regex,
} from "vee-validate/dist/rules";
import VueRecaptcha from 'vue-recaptcha';
extend("required", required);
extend("email", email);
extend("numeric", numeric);
extend("max", max);
extend("alpha", alpha);
let locale = document.getElementById("js-lang").value;
localize(locale, require(`vee-validate/dist/locale/${locale}.json`));


Vue.component('vue-recaptcha', VueRecaptcha);

//#region navbar sticky
const stickybar = document.querySelector(".navbar-main");
let stickybarTop = stickybar.offsetTop;
window.addEventListener("scroll", () => {
    if (window.scrollY > stickybarTop) {
        stickybar.classList.add("navbar-main--sticky");
    } else {
        stickybar.classList.remove("navbar-main--sticky");
    }
});
//#endregion

//#region offcavas mobile
$("[data-trigger]").on("click", function () {
    var trigger_id = $(this).attr('data-trigger');
    $(trigger_id).toggleClass("show");
    $('body').toggleClass("offcanvas-active");
});

// close button 
$(".close-icon").on("click", function (e) {
    $(".navbar-collapse").removeClass("show");
    $("body").removeClass("offcanvas-active");
});
//#endregion

//#region Form Contatti

const elContactsForm = document.getElementById("ContactsForm");
if (elContactsForm) {
    const ContactsForm = new Vue({
        el: "#ContactsForm",
        data() {
            return {
                formData: {
                    surname: "",
                    societa: "",
                    name: "",
                    phone: "",
                    fax: "",
                    email: "",
                    message: "",
                    privacy: "",
                },
                sent: false,
                sending: false,
            };
        },
        components: { ValidationProvider, ValidationObserver },
        methods: {
            onSubmit() {
                this.sending = true;
                var formData = new FormData();
                for (let key in this.formData) {
                    formData.append(key, this.formData[key]);
                }
                axios
                    .post(window.location, formData)
                    .then((res) => {
                        //this.$refs.quoteFormAnchor.scrollIntoView(false);
                        this.sent = true;
                        this.sending = false;

                        window.scrollTo(0, elSignUpForm);
                    })
                    .catch((err) => {
                        this.sending = false;
                    });
            }
        },
        created() {
            let qs = new URLSearchParams(window.location.search);
            this.formData.message = qs.get("message");
            // to test faster
            /* this.formData.name = "";
            this.formData.surname = "";
            this.formData.city = "";
            this.formData.country = "";
            this.formData.email = "";
            this.formData.phone = "";
            this.formData.message = "";
            this.formData.privacy = ""; */
            // end
        }
    });

    $(function () {
        //Mappa
        const map = new google.maps.Map(document.getElementById("map"), {
            zoom: 8,
            center: { lat: 45.125, lng: 9.76 },
        });

        //Marker Sede 1
        const SedeStudio1 = { lat: 44.79584225729472, lng: 10.324807593467048 };
        var MarkerSede1 = new google.maps.Marker({
            position: SedeStudio1,
            map
        });
        var infWindSede1 = new google.maps.InfoWindow({
            content: "<strong>PARMA</strong> <br /> <br />Viale Basetti, 14 - 43121 <br /> <br /> TEL. +39 0521 233290"
        });
        MarkerSede1.addListener('click', function () {
            infWindSede1.open(map, MarkerSede1);
        });

        //Marker Sede 2
        const SedeStudio2 = { lat: 45.46149818133695, lng: 9.205021584650712 };
        var MarkerSede2 = new google.maps.Marker({
            position: SedeStudio2,
            map
        });
        var infWindSede2 = new google.maps.InfoWindow({
            content: "<strong>MILANO</strong> <br /> <br />Via Fontana 18 - 20122<br /> <br /> TEL. +39 02 7608211"
        });
        MarkerSede2.addListener('click', function () {
            infWindSede2.open(map, MarkerSede2);
        });
    });
}

//#endregion 


